<template>
  <v-col cols="10" sm="6" class="pa-0 mt-10 pt-10 text-left mx-auto">
    <div
      class="font-bold text-2xl sm:text-4xl mt-10 pt-10 text-capitalize"
      v-if="pageName.includes('detail')">
      {{ dataItems.title }}
    </div>
    <div
      class="font-bold text-2xl sm:text-4xl mt-10 pt-10 text-capitalize"
      v-else>
      {{ pageName }}
    </div>
    <div class="text-sm py-4" v-if="bahasa">
      Terakhir diubah: {{ lastUpdated || "-" }}
    </div>
    <div class="text-sm py-4" v-else>
      Last updated: {{ lastUpdated || "-" }}
    </div>
    <v-skeleton-loader
      v-if="isLoad"
      type="paragraph"
      class="mt-3"></v-skeleton-loader>
    <div class="mb-10 mt-5" v-else>
      <div v-if="dataItems" v-html="dataItems.desc" />
      <span v-else>-</span>
    </div>
  </v-col>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
export default {
  props: {
    bahasa: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      require: false,
    },
  },
  data() {
    return {
      isLoad: false,
      dataItems: [],
      baseUrl: process.env.VUE_APP_BASE_URL,
      lastUpdated: "",
    };
  },
  computed: {
    pageName() {
      let path = this.$route.name;
      let page = path.split("/").join("");
      return page.includes("-") ? page.split("-").join(" ") : page;
    },
    language() {
      return this.bahasa ? "ID" : "ENG";
    },
    urlApi() {
      let name = "";
      switch (this.pageName) {
        case "procurement":
          name = "/procurement";
          break;
        case "procurement detail":
          name = "/procurement";
          break;
        case "pengadaan":
          name = "/procurement";
          break;
        case "privacy policy":
          name = "/privacy";
          break;
        case "kebijakan privasi":
          name = "/privacy";
          break;
        case "syarat & ketentuan":
          name = "/tnc";
          break;
        case "terms & conditions":
          name = "/tnc";
          break;
      }
      return name;
    },
    linkDetail() {
      return "procurement-detail";
    },
  },
  watch: {
    language() {
      if (this.pageName.includes("detail")) {
        this.getDetail();
      } else {
        this.getData();
      }
    },
    bahasa(val) {
      if (val) {
        switch (this.pageName) {
          case "procurement":
            this.$router.push({ name: "pengadaan" });
            break;
          case "pengadaan":
            this.$router.push({ name: "pengadaan" });
            break;
          case "privacy policy":
            this.$router.push({ name: "kebijakan-privasi" });
            break;
          case "kebijakan privasi":
            this.$router.push({ name: "kebijakan-privasi" });
            break;
          case "syarat & ketentuan":
            this.$router.push({ name: "syarat-&-ketentuan" });
            break;
          case "terms & conditions":
            this.$router.push({ name: "syarat-&-ketentuan" });
            break;
          case "legal":
            this.$router.push({ name: "hukum" });
            break;
          case "hukum":
            this.$router.push({ name: "hukum" });
            break;
        }
      } else {
        switch (this.pageName) {
          case "procurement":
            this.$router.push({ name: "procurement" });
            break;
          case "pengadaan":
            this.$router.push({ name: "procurement" });
            break;
          case "privacy policy":
            this.$router.push({ name: "privacy-policy" });
            break;
          case "kebijakan privasi":
            this.$router.push({ name: "privacy-policy" });
            break;
          case "syarat & ketentuan":
            this.$router.push({ name: "terms-&-conditions" });
            break;
          case "terms & conditions":
            this.$router.push({ name: "terms-&-conditions" });
            break;
          case "legal":
            this.$router.push({ name: "legal" });
            break;
          case "hukum":
            this.$router.push({ name: "legal" });
            break;
        }
      }
    },
    pageName() {
      if (this.pageName.includes("detail")) {
        this.getDetail();
      } else {
        this.getData();
      }
    },
    page() {
      this.getData();
    },
  },
  mounted() {
    if (this.pageName.includes("detail")) {
      this.getDetail();
    } else {
      this.getData();
    }
  },
  methods: {
    goTo(link, param) {
      this.$router.push({
        name: link,
        params: {
          id: param,
        },
      });
    },
    async getData() {
      this.isLoad = true;
      let param = {
        lang: this.language,
      };
      await axios
        .get(this.baseUrl + this.urlApi, { params: param })
        .then((response) => {
          this.dataItems = response.data.data;
          this.lastUpdated = response.data.data.lastUpdate
            ? moment(response.data.data.lastUpdate).format("DD MMMM YYYY")
            : "-";
          if (
            this.pageName === "procurement" ||
            this.pageName === "pengadaan"
          ) {
            this.page = response.data.data.current_page;
            this.pageCount = response.data.data.last_page;
          }
        })
        .catch((error) => {
          Swal.fire("Failed!", error.response.data.message, "error");
        })
        .finally(() => (this.isLoad = false));
    },
    async getDetail() {
      this.isLoad = true;
      let param = {
        lang: this.language,
      };
      await axios
        .get(this.baseUrl + this.urlApi + "/" + this.id, { params: param })
        .then((response) => {
          this.dataItems = response.data.data;
          this.lastUpdated = response.data.data.latUpdate
            ? moment(response.data.data.latUpdate).format("DD MMMM YYYY")
            : "-";
        })
        .catch((error) => {
          Swal.fire("Failed!", error.response.data.message, "error");
        })
        .finally(() => (this.isLoad = false));
    },
  },
};
</script>
